import React from "react";

const Navbar = () => {
  return (
    <nav className="navbar navbar-expand-lg">
      <div className="container">
        <a className="navbar-brand" href="/">
          <img
            src={require("../img/TheOffloadCompany4_3.png")}
            className="img-fluid h-logo"
            alt="Logo"
          />
        </a>
        <button
          className="navbar-toggler"
          type="button"
          data-bs-toggle="collapse"
          data-bs-target="#navbarText"
          aria-controls="navbarText"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className="collapse navbar-collapse" id="navbarText">
          <ul className="navbar-nav ms-auto mb-2 mb-lg-0">
            <li className="nav-item">
              <a
                className="nav-link px-4 active text-uppercase"
                aria-current="page"
                href="/"
              >
                Home
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link px-4 active text-uppercase"
                href="/general-services"
              >
                General Services
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link px-4 active text-uppercase"
                href="/labeling-services"
              >
                Data/AI
              </a>
            </li>
            <li className="nav-item">
              <a
                className="nav-link px-4 active text-uppercase"
                href="/contact-us"
              >
                Contact Us
              </a>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
