import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Accordion from "../components/Accordion";
import ScrollToTopButton from "../components/ScrollToTopButton";

const LabelingService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />

      <Banner
        description="Supercharge your AI project with top talent from Central America.
            Our AI Workforce Solutions offer expert teams specializing in data
            labeling, annotation and pre-procesing across various formats. We
            handle your existing data or acquire new data tailored to your
            specific needs, whether it’s medical imagery or weather patterns.
            With our customized services and secure cloud infrastructure, you’ll
            speed up AI development while protecting your data. Experience cost
            savings, unparalleled flexibility, and a dedicated team ready to
            elevate you AI initiatives."
        imageSrc={require("../img/data_ai_banner.png")}
        altText="Banner Image"
      >
        Data Solutions: Fueling Your AI Advantage
      </Banner>

      <section>
        <div className="container py-5">
          <div
            className="d-flex justify-content-center text-center flex-column"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            <h1 className="text-uppercase font-w-700 mt-5">
              High-Quality Data Labeling
            </h1>
            <p className="mt-3">
              Accurate AI models need accurate data. Our high-quality data
              labeling delivers precise datasets for optimal performance,
              customized to your exact needs - from budget to JSON format.
            </p>
            <span className="value-span mt-3">Here's what we can handle: </span>
          </div>
        </div>

        <div
          className="container mt-5"
          data-aos="fade-left"
          data-aos-duration="1500"
        >
          <Accordion
            imageSrc={require("../img/icons/tag (1).png")}
            buttonText="Geospatial Data Tagging"
            bodyText="Annotate maps, satellite imagery, and aerial photographs to identify weather patterns, landmarks, buildings, roads, and other geographical features."
            accordionId="accordionExample1"
            collapseId="collapseOne"
            dataBsParent="accordionExample1"
          />
          <Accordion
            imageSrc={require("../img/icons/oscar.png")}
            buttonText="Entertainment Content Tagging"
            bodyText="Categorize and tag movies, TV shows, music, and other media."
            accordionId="accordionExample2"
            collapseId="collapseTwo"
            dataBsParent="accordionExample2"
          />
          <Accordion
            imageSrc={require("../img/icons/lungs.png")}
            buttonText="Medical Image Tagging"
            bodyText=" Precisely annotate medical images such as X-rays, CT scans, and MRIs."
            accordionId="accordionExample3"
            collapseId="collapseThree"
            dataBsParent="accordionExample3"
          />
          <Accordion
            imageSrc={require("../img/icons/pricing.png")}
            buttonText="Real Estate Data Tagging"
            bodyText="Categorize and tag property images and descriptions with relevant details."
            accordionId="accordionExample4"
            collapseId="collapseFour"
            dataBsParent="accordionExample4"
          />
          <Accordion
            imageSrc={require("../img/icons/cow.png")}
            buttonText="Agricultural Data Tagging"
            bodyText="Label images and videos of crops, livestock, and farm equipment."
            accordionId="accordionExample5"
            collapseId="collapseFive"
            dataBsParent="accordionExample5"
          />
          <Accordion
            imageSrc={require("../img/icons/request.png")}
            buttonText="Bring Us Your Own Data"
            bodyText="Don't know what you're looking for? We will define the problem for you and make recommendations on what you need and how we can get it for you."
            accordionId="accordionExample6"
            collapseId="collapseSix"
            dataBsParent="accordionExample6"
          />
        </div>
      </section>

      <section>
        <div className="container py-5">
          <div
            className="d-flex flex-wrap"
            data-aos="fade-in"
            data-aos-duration="1500"
          >
            <div className="p-2 self-width my-2 d-flex align-items-start justify-content-center flex-column">
              <h2 className="text-uppercase">AI Workforce Solutions</h2>
              <h5 className="text-uppercase">Here’s what sets us apart:</h5>
            </div>
            <div className="p-2 self-width my-2">
              <div className="d-flex solution-div m-0">
                <div className="p-2 flex-fill d-flex align-items-center justify-content-center flex-column w-20">
                  <img
                    src={require("../img/icons/talent-search.png")}
                    className="img-fluid price-img"
                    alt=""
                  />
                </div>
                <div className="p-2 flex-fill d-flex align-items-start justify-content-center flex-column w-80">
                  <h5>Talent expertise</h5>
                  <p className="para-s m-0">
                    Specialists in data labeling, annotation, and pre-processing
                    ensure your AI models have the fuel they need to perform at
                    their peak.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-2 self-width my-2">
              <div className="d-flex solution-div m-0">
                <div className="p-2 flex-fill d-flex align-items-center justify-content-center flex-column w-20">
                  <img
                    src={require("../img/icons/infrastructure.png")}
                    className="img-fluid price-img"
                    alt=""
                  />
                </div>
                <div className="p-2 flex-fill d-flex align-items-start justify-content-center flex-column w-80">
                  <h5>Reliable infrastructure</h5>
                  <p className="para-s m-0">
                    Our state-of-the-art technology and virtual monitoring
                    ensure smooth operations and minimal downtime for maximum
                    efficiency.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-2 self-width my-2">
              <div className="d-flex solution-div m-0">
                <div className="p-2 flex-fill d-flex align-items-center justify-content-center flex-column w-20">
                  <img
                    src={require("../img/icons/svg.png")}
                    className="img-fluid price-img"
                    alt=""
                  />
                </div>
                <div className="p-2 flex-fill d-flex align-items-start justify-content-center flex-column w-80">
                  <h5>Cost-effectiveness</h5>
                  <p className="para-s m-0">
                    Benefit from significant cost savings through Central
                    America's competitive talent market and our competitive
                    platform access rates.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-2 self-width my-2">
              <div className="d-flex solution-div m-0">
                <div className="p-2 flex-fill d-flex align-items-center justify-content-center flex-column w-20">
                  <img
                    src={require("../img/icons/enlarge.png")}
                    className="img-fluid price-img"
                    alt=""
                  />
                </div>
                <div className="p-2 flex-fill d-flex align-items-start justify-content-center flex-column w-80">
                  <h5>Scalability</h5>
                  <p className="para-s m-0">
                    Our platform allows you to seamlessly scale your team up or
                    down as needed, reacting quickly to evolving project
                    demands.
                  </p>
                </div>
              </div>
            </div>
            <div className="p-2 self-width my-2">
              <div className="d-flex solution-div m-0">
                <div className="p-2 flex-fill d-flex align-items-center justify-content-center flex-column w-20">
                  <img
                    src={require("../img/icons/cyber-security.png")}
                    className="img-fluid price-img"
                    alt=""
                  />
                </div>
                <div className="p-2 flex-fill d-flex align-items-start justify-content-center flex-column w-80">
                  <h5>Security focus</h5>
                  <p className="para-s m-0">
                    Rigorous data security protocols protect your intellectual
                    property.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-lg-5">
          <div
            className="d-flex justify-content-center text-center align-items-center flex-column mb-5"
            data-aos="zoom-in-up"
            data-aos-duration="1500"
          >
            <span className="value-span">pricing model</span>
            <h1 className="text-uppercase font-w-700">Our Pricing Model:</h1>
            <p className="mt-4 w-70">
              Our transparent pricing model centers on delivering clear value
              for your investment. To ensure precise cost estimates, we provide
              individual cost proposals tailored to your project. These
              proposals include the following considerations:
            </p>
          </div>

          <div className="row py-5 position-relative">
            <div className="position-relative price-div">
              <img
                src={require("../img/pricing.png")}
                className="img-fluid price-bg"
                alt=""
              />
            </div>
            <div
              className="col-md-6"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="d-flex pricing-div m-0 float-start my-3">
                <div className="p-2 flex-fill d-flex align-items-center justify-content-center flex-column w-30">
                  <img
                    src={require("../img/icons/cost.png")}
                    className="img-fluid price-img"
                    alt=""
                  />
                </div>
                <div className="p-2 flex-fill d-flex align-items-start justify-content-center flex-column w-70">
                  <h5>Labor</h5>
                  <p className="para-s m-0">
                    Costs associated with our skilled workforce.
                  </p>
                </div>
              </div>
              <div className="d-flex pricing-div m-0 float-start my-3">
                <div className="p-2 flex-fill d-flex align-items-center justify-content-center flex-column w-30">
                  <img
                    src={require("../img/icons/certificate.png")}
                    className="img-fluid price-img"
                    alt=""
                  />
                </div>
                <div className="p-2 flex-fill d-flex align-items-start justify-content-center flex-column w-70">
                  <h5>Software Licenses</h5>
                  <p className="para-s m-0">
                    Necessary software tools for your project's success. Either
                    custom made or out of the box
                  </p>
                </div>
              </div>
              <div className="d-flex pricing-div m-0 float-start my-3">
                <div className="p-2 flex-fill d-flex align-items-center justify-content-center flex-column w-30">
                  <img
                    src={require("../img/icons/inventory.png")}
                    className="img-fluid price-img"
                    alt=""
                  />
                </div>
                <div className="p-2 flex-fill d-flex align-items-start justify-content-center flex-column w-70">
                  <h5>Data Specific Requirements</h5>
                  <p className="para-s m-0">
                    Any unique costs associated with getting you the data you
                    need.
                  </p>
                </div>
              </div>
            </div>
            <div
              className="col-md-6"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div className="d-flex pricing-div m-0 float-end my-3">
                <div className="p-2 flex-fill d-flex align-items-center justify-content-center flex-column w-30">
                  <img
                    src={require("../img/icons/cyber-security.png")}
                    className="img-fluid price-img"
                    alt=""
                  />
                </div>
                <div className="p-2 flex-fill d-flex align-items-start justify-content-center flex-column w-70">
                  <h5>IT/Cybersecurity Needs</h5>
                  <p className="para-s m-0">
                    Essential technical infrastructure & security measures.
                  </p>
                </div>
              </div>
              <div className="d-flex pricing-div m-0 float-end my-3">
                <div className="p-2 flex-fill d-flex align-items-center justify-content-center flex-column w-30">
                  <img
                    src={require("../img/icons/compliant.png")}
                    className="img-fluid price-img"
                    alt=""
                  />
                </div>
                <div className="p-2 flex-fill d-flex align-items-start justify-content-center flex-column w-70">
                  <h5>Legal/Compliance Needs</h5>
                  <p className="para-s m-0">
                    Adherence to relevant regulations and legal standards.
                  </p>
                </div>
              </div>

              <div className="d-flex pricing-div m-0 float-end my-3">
                <div className="p-2 flex-fill d-flex align-items-center justify-content-center flex-column w-30">
                  <img
                    src={require("../img/icons/tax.png")}
                    className="img-fluid price-img"
                    alt=""
                  />
                </div>
                <div className="p-2 flex-fill d-flex align-items-start justify-content-center flex-column w-70">
                  <h5>Management Fee</h5>
                  <p className="para-s m-0">
                    A management fee on an hourly rate is added to the total
                    cost of these elements. You only pay for what you use.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <ScrollToTopButton />
    </div>
  );
};

export default LabelingService;
