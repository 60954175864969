import React from "react";
import "bootstrap/dist/css/bootstrap.min.css";

const Accordion = ({
  imageSrc,
  buttonText,
  bodyText,
  accordionId,
  collapseId,
  dataBsParent,
}) => {
  return (
    <div className="d-flex accordin-div">
      <div className="p-2 d-flex justify-content-center align-items-center accor-mob-20">
        <img
          src={imageSrc}
          className="img-fluid acco-img"
          alt="Accordion Icon"
        />
      </div>
      <div className="p-2 flex-fill d-flex justify-content-center align-items-center accor-mob-80">
        <div className="accordion w-100" id={accordionId}>
          <div className="accordion-item">
            <h1 className="accordion-header">
              <button
                className="accordion-button collapsed"
                type="button"
                data-bs-toggle="collapse"
                data-bs-target={`#${collapseId}`}
                aria-expanded="false"
                aria-controls={collapseId}
              >
                {buttonText}
              </button>
            </h1>
            <div
              id={collapseId}
              className="accordion-collapse collapse"
              data-bs-parent={`#${dataBsParent}`}
            >
              <div className="accordion-body py-0">{bodyText}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Accordion;
