import React from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import ScrollToTopButton from "../components/ScrollToTopButton";
import { useNavigate } from "react-router-dom";

const Home = () => {
   const navigate = useNavigate();

   const handleNavigateToLabelingServices = () => {
     navigate("/labeling-services");
  };
  
  const handleNavigateToContactUs = () => {
    navigate("/contact-us");
  };
  return (
    <div>
      <Navbar />

      <Banner
        description="Our tailored solutions combine top talent from Central America with cutting-edge technology to boost your business efficiency. We build new, dedicated teams from scratch that seamlessly integrate into your company, offering expertise in finance, marketing, design, research, customer support and more. Focus on growth while we handle the rest."
        imageSrc={require("../img/Header-Banner.png")}
        altText="Banner Image"
      >
        Next-Level <br />
        <span className="position-relative blue-box">
          Talent & Tech,
          <img
            src={require("../img/dots.png")}
            className="img-fluid position-absolute top-0 e-1"
            alt=""
          />
        </span>
        <br />
        At Your Service
      </Banner>

      <section>
        <div className="container py-5">
          <div className="d-flex justify-content-center text-center">
            <h1
              className="text-uppercase font-w-700 w-30 mt-5 mob-text"
              data-aos="zoom-in"
              data-aos-duration="1500"
            >
              What’s It Like To Work With Us?
            </h1>
          </div>
        </div>
        <div className="container position-relative con-h">
          <div className="ellipse-shape">
            <div className="ellipse-3-copy-10-holder">
              <img
                className="text-22"
                src={require("../img/1.png")}
                alt="1"
                width="15"
                height="28"
                title="1"
              />
            </div>

            <div className="ellipse-3-copy"></div>
          </div>

          <div className="d-flex flex-wrap py-3">
            <div>
              <p
                className="text-9"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                Step 1
              </p>
              <p
                className="text-8"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                First Week
              </p>
              <p
                className="text-10"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                Let's start by understanding what's keeping you up at night. We
                don't just throw bodies at a problem. Whether you're in tech,
                finance, or run a lemonade stand, we'll focus on what truly
                impacts your bottom line. Then, we'll figure out the resources
                needed and give you a ballpark figure. No guesswork, no
                unrealistic commitments.
              </p>
            </div>

            <div>
              <div className="step-1-mob">
                <img
                  src={require("../img/first-week.png")}
                  className="img-fluid"
                  alt=""
                  data-aos="fade-left"
                  data-aos-duration="1500"
                />
              </div>
              <div className="step-1-col"></div>
            </div>
          </div>

          <div
            className="d-flex flex-wrap py-3"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <div>
              <p
                className="text-12"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                Step 2
              </p>
              <p
                className="text-11"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                First Month
              </p>
              <p
                className="text-13"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                While we find the right people, we nail down the process
                together. We'll work closely with you to understand your current
                workflow and identify the key tasks involved. This will involve
                creating a clear picture of what needs to be done, ensuring a
                smooth flow, and defining the ideal roles to handle them
                efficiently. Our goal? Find and train the most efficient team
                possible – transparency and collaboration are key here.
              </p>
            </div>

            <div>
              <div className="step-1-mob">
                <img
                  src={require("../img/First-Month.png")}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="layer-112-holder"></div>
            </div>
          </div>

          <div
            className="d-flex flex-wrap py-3"
            data-aos="fade-left"
            data-aos-duration="1500"
          >
            <div>
              <p
                className="text-15"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                Step 3
              </p>
              <p
                className="text-14"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                First Quarter
              </p>

              <p
                className="text-16"
                data-aos="fade-left"
                data-aos-duration="1500"
              >
                Your team, trained our way. We need sample work to prep your new
                staff. &nbsp;This is a true partnership, so we'll be transparent
                about our rapid onboarding process. &nbsp;We'll test skills,
                rigorously track performance, and be honest if things aren't
                clicking – course-correction happens early.
              </p>
            </div>
            <div>
              <div className="step-1-mob">
                <img
                  src={require("../img/First-Quarter.png")}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="layer-107"></div>
            </div>
          </div>

          <div
            className="d-flex flex-wrap py-3"
            data-aos="fade-right"
            data-aos-duration="1500"
          >
            <div>
              <p
                className="text-18"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                Step 4
              </p>
              <p
                className="text-17"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                First 6 months
              </p>
              <p
                className="text-19"
                data-aos="fade-right"
                data-aos-duration="1500"
              >
                First 6 months: Welcome to the 'ownership without the hassle'
                stage. We don't just manage your team, we embed ourselves in
                your company. You'll have a live dashboard to assign tasks,
                track progress, and see those key performance numbers. We handle
                the day-to-day issues, but you always have full visibility. Have
                a problem? Raise it with your manager.
              </p>
            </div>
            <div>
              <div className="step-1-mob">
                <img
                  src={require("../img/First-6-Months.png")}
                  className="img-fluid"
                  alt=""
                />
              </div>
              <div className="col-3-6"></div>
            </div>
          </div>

          <div className="col-shape">
            <div className="wrapper-17">
              <div className="ellipse-3-copy-2"></div>
              <div className="ellipse-3-copy-2-holder">
                <img
                  className="text-20"
                  src={require("../img/2.png")}
                  alt="2"
                  width="21"
                  height="28"
                  title="2"
                />
              </div>
            </div>
            <div className="wrapper-18">
              <div className="ellipse-3-copy-3"></div>
              <div className="ellipse-3-copy-3-holder">
                <img
                  className="text-21"
                  src={require("../img/3.png")}
                  alt="3"
                  width="21"
                  height="29"
                  title="3"
                />
              </div>
            </div>
            <div className="ellipse-3-copy-4"></div>
          </div>

          <div className="ellipse-3-copy-5"></div>
          <div className="ellipse-3-copy-4-holder">
            <img
              className="text-23"
              src={require("../img/4.png")}
              alt="4"
              width="23"
              height="28"
              title="4"
            />
          </div>
          <img
            className="layer-72"
            src={require("../img/layer_72.png")}
            alt=""
            width="53"
            height="50"
          />
        </div>
      </section>

      <section>
        <div className="container py-5">
          <div
            className="d-flex justify-content-center text-center"
            data-aos="zoom-in-up"
            data-aos-duration="1500"
          >
            <h1 className="text-uppercase font-w-700">Our Services</h1>
          </div>

          <div className="row py-5">
            <div
              className="col-md-6"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div className="card card-d blue-border">
                <div className="card-body">
                  <img
                    src={require("../img/icons/people.png")}
                    className="img-fluid h-s-100"
                    alt=""
                  />
                  <h3 className="card-title mt-3">
                    Outsourced Speciality Team
                  </h3>

                  <p className="card-text mt-4">
                    Elevate your operations with bespoke expertise, cutting-edge
                    technology, and cost efficiency – all managed for you, so
                    you can focus on growth.
                  </p>
                  <div className="mt-4">
                    <button
                      className="btn-s-blue position-relative"
                      onClick={handleNavigateToContactUs}
                    >
                      <img
                        src={require("../img/dots.png")}
                        className="img-fluid position-absolute top-0 e-1"
                        alt=""
                      />
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
            <div
              className="col-md-6"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div className="card card-d blue-border">
                <div className="card-body">
                  <img
                    src={require("../img/icons/tag.png")}
                    className="img-fluid h-s-100"
                    alt=""
                  />
                  <h3 className="card-title mt-3">Data/AI</h3>

                  <p className="card-text mt-4">
                    Unlock the potential of Central America's highly-skilled
                    workforce with our AI Workforce Solutions, designed to
                    supercharge your AI development processes
                  </p>
                  <div className="mt-4">
                    <button
                      className="btn-s-blue position-relative"
                      onClick={handleNavigateToLabelingServices}
                    >
                      <img
                        src={require("../img/dots.png")}
                        className="img-fluid position-absolute top-0 e-1"
                        alt=""
                      />
                      Learn More
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div
          className="container py-lg-5"
          data-aos="zoom-in-up"
          data-aos-duration="1500"
        >
          <div className="d-flex justify-content-center text-center flex-column mb-5">
            <span className="value-span">OUR VALUE PROPOSITION</span>
            <h1 className="text-uppercase font-w-700">
              GET RESULTS WITHOUT THE RISK
            </h1>
          </div>

          <div className="row py-5">
            <div className="col-md-6 p-80 border-s-r">
              <div className="text-center">
                <img
                  src={require("../img/icons/operation.png")}
                  className="img-fluid value-img-h"
                  alt=""
                />
                <h3 className="font-30 mt-4">Streamlined Operations</h3>
                <p className="mt-4">
                  We get down to business fast, saving you time and money by
                  pinpointing the core of your operational challenges and
                  providing swift cost assessments.
                </p>
              </div>
            </div>
            <div className="col-md-6 p-80">
              <div className="text-center">
                <img
                  src={require("../img/icons/growth.png")}
                  className="img-fluid value-img-h"
                  alt=""
                />
                <h3 className="font-30 mt-4">Cost Optimization</h3>
                <p className="mt-4">
                  We get down to business fast, saving you time and money by
                  pinpointing the core of your operational challenges and
                  providing swift cost assessments.
                </p>
              </div>
            </div>
            <div className="col-md-6 p-80 border-s-t border-s-r">
              <div className="text-center">
                <img
                  src={require("../img/icons/security.png")}
                  className="img-fluid value-img-h"
                  alt=""
                />
                <h3 className="font-30 mt-4">Adamant Data Security</h3>
                <p className="mt-4">
                  Our advanced IT protocols ensure the utmost security for your
                  data. Leveraging Google Cloud's cutting-edge encryption and
                  security features, we implement stringent access controls and
                  real-time monitoring.
                </p>
              </div>
            </div>
            <div className="col-md-6 p-80 border-s-t">
              <div className="text-center">
                <img
                  src={require("../img/icons/financial-risk.png")}
                  className="img-fluid value-img-h"
                  alt=""
                />
                <h3 className="font-30 mt-4">Risk Mitigation</h3>
                <p className="mt-4">
                  We remove the headache of team management through. proactive
                  oversight, analytics, and continuous optimization. Our
                  commitment reduces your risk and frees you to focus on your
                  core business.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <ScrollToTopButton />
    </div>
  );
};

export default Home;
