import React, { useEffect } from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Footer from "../components/Footer";

const ContactUs = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />

      <Banner
        description={
          <>
            Curious about what we can do for you? <br />
            Drop us a line! We’re all about quick turnarounds, but since we’re{" "}
            <br />a specialized team, sometimes we get booked up. Get in touch
            now so <br /> we can prioritize your project and get you on the fast
            track.
          </>
        }
        imageSrc={require("../img/contact-us.png")}
        altText="Banner Image"
        // email={{
        //   imgSrc: require("../img/contact_mail.png"),
        //   imgAlt: "Email Icon",
        //   title: "Email Us",
        //   emailAddress: "info@theoffload.co",
        // }}
      >
        Contact Us
      </Banner>

      <div className="container py-lg-5 my-lg-5">
        <iframe
          className="w-100"
          src="https://docs.google.com/forms/d/e/1FAIpQLSeH_XoUwUlohSARILKTKhetIZll1E9ll-6LWsXkYyu8vKbkng/viewform?embedded=true"
          width="640"
          height="1183"
          title="Google Forms Survey"
        >
          Loading…
        </iframe>
      </div>

      <Footer />
    </div>
  );
};

export default ContactUs;
