import React from "react";
import Navbar from "../components/Navbar";
import Banner from "../components/Banner";
import Footer from "../components/Footer";
import Accordion from "../components/Accordion";
import ScrollToTopButton from "../components/ScrollToTopButton";

const GeneralService = () => {
  return (
    <div>
      <Navbar />

      <Banner
        description="Our tailored solutions combine top talent from Central America with
            cutting-edge technology to boost your business efficiency. We build
            new, dedicated teams from scratch that seamlessly integrate into
            your company, offering expertise in finance, marketing, design,
            research, customer support and more. Focus on growth while we handle
            the rest."
        imageSrc={require("../img/services_banner.png")}
        altText="Banner Image"
      >
        Your Goals, <br />
        Our Team: <br />
        Custom-Built <br />
        Outsourced Teams
      </Banner>

      <section>
        <div className="container py-5">
          <div
            className="d-flex justify-content-center text-center flex-column"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            <h1 className="text-uppercase font-w-700 mt-5">
              Adaptive Solutions for Diverse Needs
            </h1>
            <p className="fw-bold mt-3">
              Our capabilities span a wide array of industries, ready to cater
              to the varied project requirements you might have:
            </p>
          </div>
        </div>

        <div
          className="container mt-5"
          data-aos="fade-left"
          data-aos-duration="1500"
        >
          <Accordion
            imageSrc={require("../img/icons/customer-service.png")}
            buttonText="Customer Support & Tech Assistance"
            bodyText="Elevate your customer service with our dedicated support and tech assistance teams."
            accordionId="accordionExample1"
            collapseId="collapseOne1"
            dataBsParent="accordionExample1"
          />
          <Accordion
            imageSrc={require("../img/icons/presentation.png")}
            buttonText="Business Solutions"
            bodyText="From accounting practices to comprehensive business analytics, our expertise covers all aspects of your business needs."
            accordionId="accordionExample2"
            collapseId="collapseOne2"
            dataBsParent="accordionExample2"
          />
          <Accordion
            imageSrc={require("../img/icons/painting.png")}
            buttonText="Creative Services"
            bodyText="Unleash creativity with our talented graphic designers, ready to bring your vision to life."
            accordionId="accordionExample3"
            collapseId="collapseOne3"
            dataBsParent="accordionExample3"
          />
          <Accordion
            imageSrc={require("../img/icons/research.png")}
            buttonText="Research and Development"
            bodyText="Advance your projects with our support in medical research and other R&D activities, offering specialized knowledge and expertise."
            accordionId="accordionExample4"
            collapseId="collapseOne4"
            dataBsParent="accordionExample4"
          />

          <div className="text-center mt-5">
            <p className="fw-bold">
              No matter your industry or challenge, we’re equipped to amplify
              your operations with dedicated teams and state-of-the-art
              technology solutions. Just reach out to explore how we can tailor
              our services to fit your specific needs.
            </p>
          </div>
        </div>
      </section>
      <section>
        <div className="container py-5">
          <div
            className="d-flex justify-content-center text-center flex-column"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            <h1 className="text-uppercase font-w-700 mt-5">
              How do we make it work?
            </h1>
            <p>
              Easy - we try to find existing vendors to offload these costs. If
              not, we build it ourselves.
            </p>
          </div>

          <div className="row bg-white-self my-5">
            <div
              className="col-md-6 my-4 d-flex justify-content-center flex-column px-5 position-relative"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <h1 className="text-uppercase font-w-700 mt-2 mb-3">
                Collaborative Task Management
              </h1>
              <p className="mt-3">
                Experience unmatched project visibility with our board-based
                task management platform. Designed for intuitive use and
                real-time updates, it ensures efficient coordination across all
                levels of project management.
              </p>
            </div>
            <div
              className="col-md-6 my-4 d-flex justify-content-center flex-column px-5"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div>
                <img
                  src={require("../img/Layer 115.png")}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>

          <div className="row bg-white-self my-5">
            <div
              className="col-md-6 my-4 d-flex justify-content-center flex-column px-5"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <div>
                <img
                  src={require("../img/monitor.png")}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
            <div
              className="col-md-6 my-4 d-flex justify-content-center flex-column px-5 position-relative"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <h1 className="text-uppercase font-w-700 mt-2 mb-3">
                Virtual Monitor and Data Management
              </h1>
              <p className="mt-3">
                Our cloud-based, secure, and efficient data management system is
                tailored to your needs. Whether you require basic browsing
                capabilities or complex computational power, our technology
                adapts to your project demands while emphasizing security and
                cost efficiency.
              </p>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-lg-5">
          <div
            className="d-flex justify-content-center text-center align-items-center flex-column mb-5"
            data-aos="zoom-in"
            data-aos-duration="1500"
          >
            <span className="value-span">
              Pricing Platform: Transparent and Tailored
            </span>
            <h1 className="text-uppercase font-w-700">
              Customizable, Transparent Pricing
            </h1>
            <p className="mt-4 w-70">
              Our transparent pricing model centers on delivering clear value
              for your investment. To ensure precise cost estimates, we provide
              individual cost proposals tailored to your project. These
              proposals include the following considerations:
            </p>
          </div>

          <div
            className="row py-5 position-relative"
            data-aos="zoom-in-up"
            data-aos-duration="1500"
          >
            <div className="position-relative price-div">
              <img
                src={require("../img/pricing.png")}
                className="img-fluid price-bg"
                alt=""
              />
            </div>
            <div className="col-md-6">
              <div className="d-flex pricing-div m-0 float-start my-3">
                <div className="p-2 flex-fill d-flex align-items-center justify-content-center flex-column w-30">
                  <img
                    src={require("../img/icons/cost.png")}
                    className="img-fluid price-img"
                    alt=""
                  />
                </div>
                <div className="p-2 flex-fill d-flex align-items-start justify-content-center flex-column w-70">
                  <h5>Labor</h5>
                  <p className="para-s m-0">
                    Costs associated with our skilled workforce.
                  </p>
                </div>
              </div>
              <div className="d-flex pricing-div m-0 float-start my-3">
                <div className="p-2 flex-fill d-flex align-items-center justify-content-center flex-column w-30">
                  <img
                    src={require("../img/icons/certificate.png")}
                    className="img-fluid price-img"
                    alt=""
                  />
                </div>
                <div className="p-2 flex-fill d-flex align-items-start justify-content-center flex-column w-70">
                  <h5>Software Licenses</h5>
                  <p className="para-s m-0">
                    Necessary software tools for your project's success. Either
                    custom made or out of the box
                  </p>
                </div>
              </div>
              <div className="d-flex pricing-div m-0 float-start my-3">
                <div className="p-2 flex-fill d-flex align-items-center justify-content-center flex-column w-30">
                  <img
                    src={require("../img/icons/inventory.png")}
                    className="img-fluid price-img"
                    alt=""
                  />
                </div>
                <div className="p-2 flex-fill d-flex align-items-start justify-content-center flex-column w-70">
                  <h5>Miscellaneous Client-Specific Requirements</h5>
                  <p className="para-s m-0">
                    Any unique needs particular to your project.
                  </p>
                </div>
              </div>
            </div>
            <div className="col-md-6">
              <div className="d-flex pricing-div m-0 float-end my-3">
                <div className="p-2 flex-fill d-flex align-items-center justify-content-center flex-column w-30">
                  <img
                    src={require("../img/icons/cyber-security.png")}
                    className="img-fluid price-img"
                    alt=""
                  />
                </div>
                <div className="p-2 flex-fill d-flex align-items-start justify-content-center flex-column w-70">
                  <h5>IT/Cybersecurity Needs</h5>
                  <p className="para-s m-0">
                    Essential technical infrastructure & security measures.
                  </p>
                </div>
              </div>
              <div className="d-flex pricing-div m-0 float-end my-3">
                <div className="p-2 flex-fill d-flex align-items-center justify-content-center flex-column w-30">
                  <img
                    src={require("../img/icons/compliant.png")}
                    className="img-fluid price-img"
                    alt=""
                  />
                </div>
                <div className="p-2 flex-fill d-flex align-items-start justify-content-center flex-column w-70">
                  <h5>Legal/Compliance Needs</h5>
                  <p className="para-s m-0">
                    Adherence to relevant regulations and legal standards.
                  </p>
                </div>
              </div>

              <div className="d-flex pricing-div m-0 float-end my-3">
                <div className="p-2 flex-fill d-flex align-items-center justify-content-center flex-column w-30">
                  <img
                    src={require("../img/icons/tax.png")}
                    className="img-fluid price-img"
                    alt=""
                  />
                </div>
                <div className="p-2 flex-fill d-flex align-items-start justify-content-center flex-column w-70">
                  <h5>Management Fee</h5>
                  <p className="para-s m-0">
                    A management fee on an hourly rate is added to the total
                    cost of these elements. You only pay for what you use.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section>
        <div className="container py-lg-5">
          <div className="row py-lg-5">
            <div
              className="col-md-6 my-4 d-flex justify-content-center flex-column px-5"
              data-aos="fade-right"
              data-aos-duration="1500"
            >
              <span className="step-font">
                Strategic Growth, Operational Excellence
              </span>
              <h1 className="text-uppercase font-w-700 mt-2 mb-3">
                The Ownership Without The Hassle
              </h1>
              <p>
                With our team at the helm, operational intricacies become
                seamless. From in-house training aligned with your standards to
                daily management and quality assurance, our reliable technology
                underpins every aspect of our operation.
                <br />
                <br />
                Full transparency and direct access to your team’s manager allow
                you to focus on strategic growth, knowing the operational
                details are meticulously managed.
              </p>
            </div>
            <div
              className="col-md-6 my-4 d-flex justify-content-center flex-column px-5"
              data-aos="fade-left"
              data-aos-duration="1500"
            >
              <div>
                <img
                  src={require("../img/ownership.png")}
                  className="img-fluid"
                  alt=""
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <Footer />

      <ScrollToTopButton />
    </div>
  );
};

export default GeneralService;
