import React from "react";

const Banner = ({ children, description, imageSrc, altText, email }) => {
  return (
    <div className="container py-lg-4">
      <div className="row banner" data-aos="fade-up" data-aos-duration="1500">
        <div className="col-lg-6 col-md-6 col-12 banner-text">
          <h1 className="banner-h text-uppercase mb-3">{children}</h1>
          <p>{description}</p>
          {email && (
            <div className="d-flex mt-4">
              <div className="d-flex justify-content-center align-items-center">
                <div className="dash-div">
                  <div className="email-div1">
                    <img
                      src={email.imgSrc}
                      className="img-fluid"
                      alt={email.imgAlt}
                    />
                  </div>
                </div>
              </div>
              <div className="d-flex justify-content-center align-items-start flex-column ps-3">
                <h5>{email.title}</h5>
                <p className="m-0 font-15">{email.emailAddress}</p>
              </div>
            </div>
          )}
        </div>
        <div className="col-lg-6 col-md-6 col-12 banner-image">
          <img src={imageSrc} className="img-fluid w-100" alt={altText} />
        </div>
      </div>
    </div>
  );
};

export default Banner;
