import React from "react";
import { useNavigate } from "react-router-dom";

const Footer = () => {
  const navigate = useNavigate();

  const handleButtonClick = () => {
    navigate("/contact-us");
  };

  return (
    <footer className="footer">
      <div className="container py-4">
        <div className="d-flex justify-content-center flex-column align-items-center gap-5">
          <a href="/">
            <img
              src={require("../img/TheOffloadCompany2_1.png")}
              className="img-fluid logo-h-170"
              alt=""
            />
          </a>

          <button
            className="btn-s-blue position-relative btn-font"
            onClick={handleButtonClick}
          >
            <img
              src={require("../img/dots.png")}
              className="img-fluid position-absolute top-0 e-1"
              alt=""
            />
            Schedule a Free Strategy Consultation
          </button>
        </div>
      </div>
      <div className="container mt-auto py-lg-5">
        <hr className="my-lg-5" />
        <div className="row py-4">
          <div className="col-md-3">
            <h4 className="text-uppercase">Site Links</h4>
            <ul className="list-unstyled mt-5">
              <li>
                <a href="/">Home</a>
              </li>
              <li>
                <a href="/general-services">General Services</a>
              </li>
              <li>
                <a href="/labeling-services">Data/AI</a>
              </li>
              <li>
                <a href="/contact-us">Contact Us</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h4 className="text-uppercase">Company</h4>
            <ul className="list-unstyled mt-5">
              <li>
                <a href="/#">Privacy Policy</a>
              </li>
              <li>
                <a href="/#">Disclaimer</a>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h4 className="text-uppercase">Contact Us</h4>
            <ul className="list-unstyled mt-5">
              <li>
                <div className="d-flex">
                  <a href="mailto:info@theoffload.co">
                    <img
                      src={require("../img/Envelope.png")}
                      className="img-fluid h-14 me-3"
                      alt=""
                    />
                    info@theoffload.co
                  </a>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <img
                    src={require("../img/map-marker-alt.png")}
                    className="img-fluid h-15 me-3 mt-3"
                    alt=""
                  />
                  30 N Gould St #12182 <br />
                  Sheridan, WY 82801 <br />
                  United States
                </div>
              </li>
            </ul>
          </div>
          <div className="col-md-3">
            <h4 className="text-uppercase">Social Media</h4>
            <ul className="list-unstyled mt-5">
              <li>
                <a href="/#" className="lindin-icon">
                  <i className="bi bi-linkedin social-icon"></i>
                </a>
              </li>
            </ul>
          </div>
        </div>
        <hr className="my-lg-5" />
        <div className="row mt-4">
          <div className="col-12 text-center">
            <p>&copy; 2024 The Offload Company. All rights reserved.</p>
            <p>
              Designed in Chicago{" "}
              <img
                src={require("../img/united-states_logo.png")}
                className="img-fluid flag-h"
                alt="United States"
              />
            </p>
            <p>
              Made in Honduras <span className="flag-icon flag-icon-hn"></span>
            </p>
          </div>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
